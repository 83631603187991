class MediaHome {
  init(){
    this.requestCredentialButton = $('.requestCredentialButton');
    this.requestCredentialButton.on('click', media_home.requestCredentialButtonOnClick);

    this.ufcMaterialsShowMoreButton = $('#ufcMaterialsShowMore');
    this.ufcMaterialsHiddenContent = this.ufcMaterialsShowMoreButton.parent().find('.hideShowLess');

    this.ufcMaterialsShowMoreButton.on('click', media_home.ufcMaterialsShowMoreButtonOnClick);
  };

  ufcMaterialsShowMoreButtonOnClick(e) {
    let state = $(this).data('state');

    if (state === 'closed') {
      $(this).data('state', 'open')
      $(this).html('<span>&#9652; Show Less &#9652;</span>');
      media_home.ufcMaterialsHiddenContent.each(function(idx){
        $(this).removeClass('hideShowLess');
      });
    } else {
      $(this).data('state', 'closed')
      $(this).html('<span>&#9662; Show More &#9662;</span>');
      media_home.ufcMaterialsHiddenContent.each(function(idx){
        $(this).addClass('hideShowLess');
      });
    }
  };

  requestCredentialButtonOnClick(e) {
    if (confirm('Are You Sure?')) {
      let $this = $(this);
      $.ajax({
        url: '/media/request_credential',
        data: {event_id: $this.data('event-id')},
        method: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(data){
          if (data['success'] == true){
            toastr.success('Request Sent.');
            $this.parent().html('<span class="chip chip__pending">Pending</span>');
          } else {
            toastr.error(data['message']);
          };
        },
        error: function(data){
          toastr.error('Unable To Send Request.');
        }
      });
    }
  };

}
export let media_home = new MediaHome();