class PersonEditModal {

  init() {
    this.cropper = null;
    this.person_edit_id = null;
    this.person_edit_hub_id = null;
    this.media_user_edit_id = null;

    this.personEditModal = $('#person_edit_modal');
    this.personEditModalClose = $('#person_edit_modal_close');
    this.personEditModalPersonName = $('#person_edit_modal_person_name');
    this.personEditModalFormHolder = $('#person_edit_modal_form_holder');

    this.personEditModalCredPicHolder = $('#person_edit_modal_cred_pic_holder');
    this.personEditModalCredPic = $('#person_edit_modal_cred_pic');
    this.personEditModalCredPicEdit = $('#person_edit_modal_cred_pic_edit');
    this.personEditModalCredPicButtonsHolder = $('#person_edit_modal_cred_pic_buttons_holder');
    this.personEditModalCredPicEditButtonHolder = $('.credPicEditButtonHolder');
    this.personEditModalCredPicEditButtonsHolder = $('.credPicEditButtonsHolder');
    this.personEditModalCredPicCropper = $('.personEditModalCredPicCropper');
    this.personEditModalCredPicSave = $('#cred_pic_save');
    this.personEditModalCredPicCancel = $('#cred_pic_cancel');
    this.personEditModalCredPicSelectorHolder = $('#cred_pic_file_selector_holder');
    this.personEditModalCredPicSelector = $('#cred_pic_file_selector');

    $('body').on('click', '.personEditButton', person_edit_modal.personEditButtonOnClick);
    this.personEditModalClose.on('click', person_edit_modal.personEditCloseOnClick);

    this.personEditModalCredPicEdit.on('click', person_edit_modal.personEditCredPicEditClick);
    this.personEditModalCredPicCropper.on('click', person_edit_modal.personEditCredPicCropperClick);
    this.personEditModalCredPicSave.on('click', person_edit_modal.personEditCredPicSaveClick);
    this.personEditModalCredPicCancel.on('click', person_edit_modal.personEditCredPicCancelClick);
    this.personEditModalCredPicSelector.on('change', person_edit_modal.personEditModalCredPicSelectorChange);

    $('body').on('submit', '#person_edit_modal_person_form', person_edit_modal.personEditModalPersonFormOnSubmit);
  };

  personEditButtonOnClick() {
    person_edit_modal.populatePersonEditModal($(this).data('person-id'));
    person_edit_modal.personEditModal.removeClass('modal__hidden');
    person_edit_modal.personEditModal.addClass('modal__visible');
  };

  personEditCloseOnClick() {
    person_edit_modal.personEditModal.removeClass('modal__visible');
    person_edit_modal.personEditModal.addClass('modal__hidden');
    person_edit_modal.destroyCropper();
  };

  populatePersonEditModal(person_id) {
    let pdata = $('#person_' + person_id).data('person-detail');

    // Toggle credential pic and form visibility
    if (person_id) {
      person_edit_modal.personEditModalCredPicHolder.removeClass('visuallyHidden');
      person_edit_modal.personEditModalCredPicButtonsHolder.removeClass('visuallyHidden');
      person_edit_modal.personEditModalFormHolder.removeClass('visuallyHidden');

      let modal_form = '';
      
      // Modal Header
      person_edit_modal.personEditModalPersonName.html(pdata.first_name + ' ' + pdata.last_name);

      // Cred Pic
      person_edit_modal.personEditModalCredPic.attr('src', null);
      person_edit_modal.personEditModalCredPicSelector.val(null);
      person_edit_modal.destroyCropper();
      person_edit_modal.showEditCredPicButton();
      person_edit_modal.person_edit_id = pdata.id;
      person_edit_modal.person_edit_hub_id = pdata.hub_id;
      person_edit_modal.personEditModalCredPic.attr('src', '/people/' + person_edit_modal.person_edit_id + '/cred_pic?rand=' + Math.random());
      
      // Form
      modal_form += '<form id="person_edit_modal_person_form">';
      modal_form += '<div class="sideBySideContainers" style="grid-template-columns: 1fr 1fr 1fr;margin-top: -35px;">';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<h5>First Name</h5><input name="first_name" type="text" value="' + person_edit_modal.emptyStringIfNull(pdata.first_name) + '" />';
      modal_form += '</div>';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<h5>Middle Name</h5><input type="text" name="middle_name" value="' + person_edit_modal.emptyStringIfNull(pdata.middle_name) + '" />';
      modal_form += '</div>';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<h5>Last Name</h5><input name="last_name" type="text" value="' + person_edit_modal.emptyStringIfNull(pdata.last_name) + '" />';
      modal_form += '</div>';
      modal_form += '</div>';
      modal_form += '<div class="sideBySideContainers" style="grid-template-columns: 1fr 1fr 1fr;">';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<input name="first_name_override" placeholder="Override" type="text" value="' + person_edit_modal.emptyStringIfNull(pdata.first_name_override) + '" />';
      modal_form += '</div>';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<input type="text" name="middle_name_override" placeholder="Override" value="' + person_edit_modal.emptyStringIfNull(pdata.middle_name_override) + '" />';
      modal_form += '</div>';
      modal_form += '<div class="sideBySideContainers--container">';
      modal_form += '<input name="last_name_override" placeholder="Override" type="text" value="' + person_edit_modal.emptyStringIfNull(pdata.last_name_override) + '" />';
      modal_form += '</div>';
      modal_form += '</div>';
      modal_form += '<h5>Phone (Country Code Required)</h5><input name="phone1" type="text" value="' + person_edit_modal.emptyStringIfNull(pdata.phone1) + '" />';
      modal_form += '<h5>Email</h5><input name="email1" type="text" value="' + person_edit_modal.emptyStringIfNull(pdata.email1) + '" />';
      modal_form += '<div class="personFormSelect"><div><h5>Access Blacklist</h5></div><div><select name="blacklisted"><option value="0" ' + ((pdata.blacklisted == 0) ? 'selected' : '') + '>No</option><option value="1" ' + ((pdata.blacklisted == 1) ? 'selected' : '') + '>Yes</option></select></div></div>';
      modal_form += '<div class="buttons"><input class="button" type="submit" name="commit" value="Update" /></div>';
      modal_form += '</form>';

      person_edit_modal.personEditModalFormHolder.html(modal_form);
    } else {
      person_edit_modal.personEditModalCredPicHolder.addClass('visuallyHidden');
      person_edit_modal.personEditModalCredPicButtonsHolder.addClass('visuallyHidden');
      person_edit_modal.personEditModalFormHolder.addClass('visuallyHidden');

      // Modal Header
      person_edit_modal.personEditModalPersonName.html('My Profile');
    }
  };

  personEditModalPersonFormOnSubmit(e) {
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: '/people/' + person_edit_modal.person_edit_id + '/ajax_update_person',
      data: $(this).serialize(),
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data, textStatus, jqXHR) {
        if (data['success']) {
          toastr.success('Person Updated.');
          setTimeout(function(){ location.reload(); }, 500);
        } else {
          toastr.error(data['message']);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        toastr.error('Person Could Not Be Updated. Please Try Again.');
      }
    });
  };

  personEditModalCredPicSelectorChange() {
    if (this.files && this.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        person_edit_modal.personEditModalCredPic.attr('src', e.target.result);
        person_edit_modal.personEditCredPicEditClick();
        person_edit_modal.personEditModalCredPicSave.removeClass('disabled');
      };
      reader.readAsDataURL(this.files[0]);
    }
  };

  personEditCredPicEditClick() {
    person_edit_modal.destroyCropper();
    person_edit_modal.cropper = new Cropper(document.getElementById('person_edit_modal_cred_pic'));
    person_edit_modal.showEditCredPicActionButtons();
  };

  personEditCredPicCropperClick() {
    if (person_edit_modal.cropper == null) { toastr.error('Unable To Edit. Please Refresh And Try Again.'); return; }
    if (this.id == 'cred_pic_rotate') {
      person_edit_modal.cropper.rotate(90);
      person_edit_modal.personEditModalCredPicSave.removeClass('disabled');
    } else {
      if (Object.keys(person_edit_modal.cropper.getCropBoxData()).length != 0) {
        person_edit_modal.personEditModalCredPic.attr('src', person_edit_modal.cropper.getCroppedCanvas({}).toDataURL());
        person_edit_modal.destroyCropper();
        person_edit_modal.cropper = new Cropper(document.getElementById('person_edit_modal_cred_pic'));
        person_edit_modal.personEditModalCredPicSave.removeClass('disabled');
      } else {
        toastr.info('Draw A Box On The Image To Crop.')
      }
    }
  };

  personEditCredPicSaveClick() {
    if ($(this).hasClass('disabled')) { return; }
    if (person_edit_modal.cropper == null) { toastr.error('Unable To Save. Please Refresh And Try Again.'); return; }
    person_edit_modal.personEditModalCredPicSave.addClass('disabled').text('Saving...');
    setTimeout(function(){
      person_edit_modal.cropper.getCroppedCanvas({}).toBlob(function (blob) {
        let form_data = new FormData();
        form_data.append('cred_pic', blob);
        $.ajax('/people/' + person_edit_modal.person_edit_id + '/ajax_save_cred_pic', {
          method: 'POST',
          beforeSend: function(xhr) { 
            xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')); 
          },
          data: form_data,
          processData: false,
          contentType: false,
          success: function (data) {
            if (data['success']) {
              toastr.success('Credential Photo Saved.');
            } else {
              toastr.error(data['message']);
            }
            person_edit_modal.personEditCredPicCancelClick();
            $('#p_' + person_edit_modal.person_edit_id + '_photo_checkmark').html('&check;');
            $('#p_' + person_edit_modal.person_edit_id + '_photo_checkmark').removeClass('red').addClass('green photo-preview');
          },
          error: function () {
            person_edit_modal.personEditModalCredPicSave.removeClass('disabled').text('Save');
            toastr.error('Unable To Save Credential Photo. Please Try Again.');
          }
        });
      }, 'image/png');
    }, 100);
  };

  personEditCredPicCancelClick() {
    person_edit_modal.personEditModalCredPic.attr('src', '/people/' + person_edit_modal.person_edit_id + '/cred_pic?rand=' + Math.random());
    person_edit_modal.personEditModalCredPicSelector.val(null);
    person_edit_modal.destroyCropper();
    person_edit_modal.showEditCredPicButton();
  };

  showEditCredPicButton() {
    person_edit_modal.personEditModalCredPicSelectorHolder.removeClass('visuallyHidden');
    person_edit_modal.personEditModalCredPicEditButtonHolder.removeClass('visuallyHidden');
    person_edit_modal.personEditModalCredPicEditButtonsHolder.addClass('visuallyHidden');
  };

  showEditCredPicActionButtons() {
    person_edit_modal.personEditModalCredPicSave.addClass('disabled').text('Save');
    person_edit_modal.personEditModalCredPicSelectorHolder.addClass('visuallyHidden');
    person_edit_modal.personEditModalCredPicEditButtonHolder.addClass('visuallyHidden');
    person_edit_modal.personEditModalCredPicEditButtonsHolder.removeClass('visuallyHidden');
  };

  destroyCropper() {
    if (person_edit_modal.cropper != null) {
      person_edit_modal.cropper.destroy();
      person_edit_modal.cropper = null;
    }
  };

  emptyStringIfNull(str) {
    if (str === null) { return ''; } else { return str; }
  };

}

export let person_edit_modal = new PersonEditModal();
