class ProfileEdit {

  init() {
    this.cropper = null;
    this.person_edit_id = $('#profile_edit').data('person-edit-id');
    this.media_user_edit_id = $('#profile_edit').data('media-user-edit-id');

    this.profileEditCredPicHolder = $('#profile_edit_cred_pic_holder');
    this.profileEditCredPic = $('#profile_edit_cred_pic');
    this.profileEditCredPicEdit = $('#profile_edit_cred_pic_edit');
    this.profileEditCredPicButtonsHolder = $('#profile_edit_cred_pic_buttons_holder');
    this.profileEditCredPicEditButtonHolder = $('.credPicEditButtonHolder');
    this.profileEditCredPicEditButtonsHolder = $('.credPicEditButtonsHolder');
    this.profileEditCredPicCropper = $('.profileEditCredPicCropper');
    this.profileEditCredPicSave = $('#cred_pic_save');
    this.profileEditCredPicCancel = $('#cred_pic_cancel');
    this.profileEditCredPicSeletorHolder = $('#cred_pic_file_selector_holder');
    this.profileEditCredPicSeletor = $('#cred_pic_file_selector');

    this.profileEditPasswordHolder = $('#profile_edit_password_holder');
    this.profileEditPasswordUpdate = $('#profile_edit_password_update');

    this.profileEditEmailUpdate = $('#profile_edit_email_update');
    this.profileEditPhoneUpdate = $('#profile_edit_phone_update');
    this.profileEditDietaryRestrictionsUpdate = $('#profile_edit_dietary_restrictions_update');

    this.profileEditCredPicEdit.on('click', profile_edit.profileEditCredPicEditClick);
    this.profileEditCredPicCropper.on('click', profile_edit.profileEditCredPicCropperClick);
    this.profileEditCredPicSave.on('click', profile_edit.profileEditCredPicSaveClick);
    this.profileEditCredPicCancel.on('click', profile_edit.profileEditCredPicCancelClick);
    this.profileEditCredPicSeletor.on('change', profile_edit.profileEditCredPicSelectorChange);

    this.profileEditPasswordUpdate.on('click', profile_edit.profileEditPasswordUpdateClick);
    //this.profileEditEmailUpdate.on('click', profile_edit.profileEditEmailUpdateClick);
    this.profileEditPhoneUpdate.on('click', profile_edit.profileEditPhoneUpdateClick);
    this.profileEditDietaryRestrictionsUpdate.on('click', profile_edit.profileEditDietaryRestrictionsUpdateClick);

    //$('body').on('submit', '#profile_edit_person_form', profile_edit.profileEditPersonFormOnSubmit);
    $('body').on('submit', '#profile_edit_editor_form', profile_edit.profileEditEditorFormOnSubmit);
  };

  profileEditCredPicSelectorChange() {
    if (this.files && this.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        profile_edit.profileEditCredPic.attr('src', e.target.result);
        profile_edit.profileEditCredPicEditClick();
        //profile_edit.profileEditCredPicSave.removeClass('disabled');
      };
      reader.readAsDataURL(this.files[0]);
    }
  };

  profileEditCredPicEditClick() {
    profile_edit.destroyCropper();
    profile_edit.cropper = new Cropper(document.getElementById('profile_edit_cred_pic'));
    profile_edit.showEditCredPicActionButtons();
  };

  profileEditCredPicCropperClick() {
    if (profile_edit.cropper == null) { toastr.error('Unable To Edit. Please Refresh And Try Again.'); return; }
    if (this.id == 'cred_pic_rotate') {
      profile_edit.cropper.rotate(90);
      //profile_edit.profileEditCredPicSave.removeClass('disabled');
    } else {
      if (Object.keys(profile_edit.cropper.getCropBoxData()).length != 0) {
        profile_edit.profileEditCredPic.attr('src', profile_edit.cropper.getCroppedCanvas({}).toDataURL());
        profile_edit.destroyCropper();
        profile_edit.cropper = new Cropper(document.getElementById('profile_edit_cred_pic'));
        profile_edit.profileEditCredPicSave.removeClass('disabled');
      } else {
        toastr.info('Draw A Box On The Image To Crop.')
      }
    }
  };

  profileEditCredPicSaveClick() {
    if ($(this).hasClass('disabled')) { toastr.info('Please Crop The Image To Proceed.'); return; }
    if (profile_edit.cropper == null) { toastr.error('Unable To Save. Please Refresh And Try Again.'); return; }
    profile_edit.profileEditCredPicSave.addClass('disabled').text('Saving...');
    setTimeout(function(){
      profile_edit.cropper.getCroppedCanvas({}).toBlob(function (blob) {
        let form_data = new FormData();
        form_data.append('cred_pic', blob);
        $.ajax('/people/' + profile_edit.person_edit_id + '/ajax_save_cred_pic', {
          method: 'POST',
          beforeSend: function(xhr) { 
            xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')); 
          },
          data: form_data,
          processData: false,
          contentType: false,
          success: function (data) {
            if (data['success']) {
              toastr.success('Credential Photo Saved.');
            } else {
              toastr.error(data['message']);
            }
            profile_edit.profileEditCredPicCancelClick();
          },
          error: function () {
            profile_edit.profileEditCredPicSave.removeClass('disabled').text('Save');
            toastr.error('Unable To Save Credential Photo. Please Try Again.');
          }
        });
      }, 'image/png');
    }, 100);
  };

  profileEditCredPicCancelClick() {
    profile_edit.profileEditCredPic.attr('src', '/people/' + profile_edit.person_edit_id + '/cred_pic?rand=' + Math.random());
    profile_edit.profileEditCredPicSeletor.val(null);
    profile_edit.destroyCropper();
    profile_edit.showEditCredPicButton();
  };

  showEditCredPicButton() {
    profile_edit.profileEditCredPicSeletorHolder.removeClass('visuallyHidden');
    profile_edit.profileEditCredPicEditButtonHolder.removeClass('visuallyHidden');
    profile_edit.profileEditCredPicEditButtonsHolder.addClass('visuallyHidden');
  };

  showEditCredPicActionButtons() {
    profile_edit.profileEditCredPicSave.addClass('disabled').text('Save');
    profile_edit.profileEditCredPicSeletorHolder.addClass('visuallyHidden');
    profile_edit.profileEditCredPicEditButtonHolder.addClass('visuallyHidden');
    profile_edit.profileEditCredPicEditButtonsHolder.removeClass('visuallyHidden');
  };

  destroyCropper() {
    if (profile_edit.cropper != null) {
      profile_edit.cropper.destroy();
      profile_edit.cropper = null;
    }
  };

  // profileEditPersonFormOnSubmit(e) {
  //   e.preventDefault();
  //   $.ajax({
  //     type: 'POST',
  //     url: '/people/' + profile_edit.person_edit_id + '/ajax_update_person',
  //     data: $(this).serialize(),
  //     beforeSend: function (xhr) {
  //       xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
  //     },
  //     success: function (data, textStatus, jqXHR) {
  //       if (data['success']) {
  //         toastr.success('Personal Contact Information Updated.');
  //         setTimeout(function(){ location.reload(); }, 500);
  //       } else {
  //         toastr.error(data['message']);
  //       }
  //     },
  //     error: function (jqXHR, textStatus, errorThrown) {
  //       toastr.error('Personal Contact Information Could Not Be Updated. Please Try Again.');
  //     }
  //   });
  // };

  profileEditEditorFormOnSubmit(e) {
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: '/media_users/' + profile_edit.media_user_edit_id + '/ajax_update_editor',
      data: $(this).serialize(),
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data, textStatus, jqXHR) {
        if (data['success']) {
          toastr.success('Editor Information Updated.');
          setTimeout(function(){ location.reload(); }, 500);
        } else {
          toastr.error(data['message']);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        toastr.error('Editor Information Could Not Be Updated. Please Try Again.');
      }
    });
  };

  profileEditPasswordUpdateClick() {
    if(($('#password').val() != '') && ($('#password').val() == $('#password_confirmation').val())) {
      $.ajax('/media_users/' + profile_edit.media_user_edit_id + '/ajax_update_password', {
        method: 'POST',
        beforeSend: function(xhr) { 
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')); 
        },
        data: {password: $('#password').val(), password_confirmation: $('#password').val()},
        success: function (data) {
          if (data['success']) {
            toastr.success('Password Updated.');
            setTimeout(function(){ location.reload(); }, 1000);
          } else {
            toastr.error(data['message']);
          }
        },
        error: function () {
          toastr.error('Unable To Update Password. Please Try Again.');
        }
      });
    } else {
      toastr.error('Passwords do not match.');
    }
  };

  // profileEditEmailUpdateClick() {
  //   if ($('#email').val() != '') {
  //     $.ajax('/media_users/' + profile_edit.media_user_edit_id + '/ajax_update_email', {
  //       method: 'POST',
  //       beforeSend: function(xhr) { 
  //         xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')); 
  //       },
  //       data: {email: $('#email').val()},
  //       success: function (data) {
  //         if (data['success']) {
  //           toastr.success('Email Updated.');
  //           setTimeout(function(){ location.reload(); }, 1000);            
  //         } else {
  //           toastr.error('Unable To Update Email. ' + data['message']);
  //         }
  //       },
  //       error: function () {
  //         toastr.error('Unable To Update Email. Please Try Again.');
  //       }
  //     });
  //   } else {
  //     toastr.error('Email Can\'t Be Blank.');
  //   }
  // };

  profileEditPhoneUpdateClick() {
    if ($('#phone').val() != '') {
      $.ajax('/media_users/' + profile_edit.media_user_edit_id + '/ajax_update_phone', {
        method: 'POST',
        beforeSend: function(xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        data: {phone: $('#phone').val()},
        success: function (data) {
          if (data['success']) {
            toastr.success('Phone Updated.');
            setTimeout(function(){ location.reload(); }, 1000);
          } else {
            toastr.error('Unable To Update Phone. ' + data['message']);
          }
        },
        error: function () {
          toastr.error('Unable To Update Phone. Please Try Again.');
        }
      });
    } else {
      toastr.error('Phone Can\'t Be Blank.');
    }
  };

  profileEditDietaryRestrictionsUpdateClick() {
    if ($('#dietary_restrictions').val() != '') {
      $.ajax('/media_users/' + profile_edit.media_user_edit_id + '/ajax_update_dietary_restrictions', {
        method: 'POST',
        beforeSend: function(xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        data: {dietary_restrictions: $('#dietary_restrictions').val()},
        success: function (data) {
          if (data['success']) {
            toastr.success('Dietary Restrictions Updated.');
            setTimeout(function(){ location.reload(); }, 1000);
          } else {
            toastr.error('Unable To Update Dietary Restrictions. ' + data['message']);
          }
        },
        error: function () {
          toastr.error('Unable To Update Dietary Restrictions. Please Try Again.');
        }
      });
    } else {
      toastr.error('Dietary Restrictions Can\'t Be Blank.');
    }
  };

}

export let profile_edit = new ProfileEdit();
