
import { event_person_edit } from './event_person_edit';
import { epm } from './epm';
import { event_person_create } from './event_person_create';
import { person_photo_preview } from '../misc/person_photo_preview';

if ($('.epm').length) {
  event_person_edit.init();
  epm.init();
  event_person_create.init();
  person_photo_preview.init();
}