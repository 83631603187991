class EventPersonCreate {
  init() {
    this.eventId = $('#event_attributes').data('event-id');
    this.eventPersonCreate = $('#person_create_modal');
    this.eventPersonCreateClose = $('#person_create_modal_close');
    this.eventPersonCreateSubmit = $('#person_create_modal_person_form_submit');

    $('body').on('click', '#personCreateButton', event_person_create.personCreateButtonOnClick);
    this.eventPersonCreateClose.on('click', event_person_create.personCreateCloseOnClick);


    $('body').on('submit', '#person_create_modal_person_form', event_person_create.personCreateFormOnSubmit);
  };

  personCreateButtonOnClick() {
    $("#import_users_modal").hide();
    event_person_create.eventPersonCreate.removeClass('modal__hidden');
    event_person_create.eventPersonCreate.addClass('modal__visible');
  };

  personCreateCloseOnClick() {
    event_person_create.eventPersonCreate.removeClass('modal__visible');
    event_person_create.eventPersonCreate.addClass('modal__hidden');
    $("#import_users_modal").show();

  };

  personCreateFormOnSubmit(e) {
    e.preventDefault();
    event_person_create.eventPersonCreateSubmit.prop('disabled', true);
    $.ajax({
      type: 'POST',
      url: '/events/' + event_person_create.eventId + '/ajax_create_person_for_event',
      data: new FormData(this),
      processData: false,
      contentType: false,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data, textStatus, jqXHR) {
        if (data['success']) {
          toastr.success('Person Created.');
          setTimeout(function () {
            location.reload();
          }, 500);
        } else {
          event_person_create.eventPersonCreateSubmit.prop('disabled', false);
          toastr.error(data['message']);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        event_person_create.eventPersonCreateSubmit.prop('disabled', false);
        toastr.error('Person Could Not Be Created. Please Try Again.');
      }
    });
  };
}

export let event_person_create = new EventPersonCreate();
