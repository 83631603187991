// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery-ui"
import Cropper from "cropperjs"
import toastr from "toastr"

window.Cropper = Cropper
window.Cropper.setDefaults(
  {
    viewMode: 0,
    autoCrop: false,
    movable: false,
    aspectRatio: 1188/1236
  }
);

window.toastr = toastr
window.toastr.options = {
  "positionClass": "toast-bottom-right"
};

Rails.start()
ActiveStorage.start()

console.log("application.js loaded");

$(() => { require("misc") });
$(() => {if ($(".events").length) { require("hub_events"); }});
$(() => {if ($(".people").length) { require("hub_people"); }});
$(() => {if ($(".profile").length || $(".registrations").length) { require("profile"); }});
$(() => {if ($(".media").length) { require("media") }});