class PersonPhotoPreview {

  init() {
    $('body').on({
      mouseenter: person_photo_preview.onPhotoPreviewHoverIn,
      mouseleave: person_photo_preview.onPhotoPreviewHoverOut
    }, '.photo-preview');
  };

  onPhotoPreviewHoverIn() {
    $('#person_image_preview').attr('src', `/people/${$(this).data('person-id')}/cred_pic?rand=${Math.random()}`);
    $('#person_image_preview').css({
        'display': 'block',
        'top': $(this).offset().top + $(this).height() + 15,
        'left': $(this).offset().left
    });
  };

  onPhotoPreviewHoverOut() {
    $('#person_image_preview').css('display', 'none');
  };

}

export let person_photo_preview = new PersonPhotoPreview();
