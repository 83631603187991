class SortableTable {

  init() {
    this.makeTablesSortable();
  };

  // Table headings with class="sortable" will become sortable
  makeTablesSortable(){
    $('body').on('click', 'th.sortable', function(){
      $(this).parent().children('th').removeClass("headerSortDown headerSortUp");
      $(this).parent().children('th').css('text-decoration','');

      if (this.asc){
        $(this).addClass("headerSortUp");
        $(this).css('text-decoration','underline');
      }else {
        $(this).addClass("headerSortDown");
        $(this).css('text-decoration','underline');
      }
      var table = $(this).parents('table').eq(0);
      var rows = table.find('tr:gt(0)').toArray().sort(comparer($(this).index()));
      this.asc = !this.asc;
      if (!this.asc){rows = rows.reverse();}
      for (var i = 0; i < rows.length; i++){table.append(rows[i]);}
    });
    const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
        v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
    )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));
    const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;
  };

}

export let sortable_table = new SortableTable();
