class Organization {

  init() {
    this.profileEditHolder = $('#profile_edit');
    this.organizationAutocomplete = $('.organizationAutocomplete');

    this.autocompleteOrganizations = this.profileEditHolder.data('organizations-json');
    this.organizationAutocomplete.autocomplete(
      { 
        source: this.autocompleteOrganizations,
        minLength: 2,
        select: function (event, ui) {}
      } 
    );
  };

}

export let organization = new Organization();
