class Materials {
  init(){
    this.downloadButton = $('.fileDownloadLink');
    this.followLinkButton = $('.fileFollowLink');

    this.downloadButton.on('click', materials.download);
    this.followLinkButton.on('click', materials.followLink);

    this.requestCredentialButton = $('.requestCredentialButton');
    this.requestCredentialButton.on('click', materials.requestCredentialButtonOnClick)
  };

  download(e){
    var filePath = $(this).data('file-path');
    $.ajax({
      url: '/media/download_file',
      data: {file_path: filePath},
      method: 'GET',
      success: function(data){
        //open file download in new tab
        if (data['success'] == true){
          window.open(data['download_url'], '_blank');
        } else {
          toastr.error('Unable To Download File.');
        };
      },
      error: function(data){
        toastr.error('Unable To Download File.');
      }
    });
  };

  followLink(e){
    window.open($(this).data('url'), '_blank');
  };

  requestCredentialButtonOnClick(e){
    if (confirm('Are You Sure?')) {
      let $this = $(this);
      $.ajax({
        url: '/media/request_credential',
        data: {event_id: $this.data('event-id')},
        method: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(data){
          if (data['success'] == true){
            toastr.success('Request Sent.');
            $this.parent().html('<span class="chip chip__pending">Pending</span>');
          } else {
            toastr.error(data['message']);
          };
        },
        error: function(data){
          toastr.error('Unable To Send Request.');
        }
      });
    }
  };

}
export let materials = new Materials();