class FilesAndLinks {
  init(){
    this.downloadButton = $('.fileDownloadLink');
    this.followLinkButton = $('.fileFollowLink');

    this.downloadButton.on('click', files_and_links.download);
    this.followLinkButton.on('click', files_and_links.followLink);
  };

  download(e){
    var filePath = $(this).data('file-path');
    $.ajax({
      url: '/media/download_file',
      data: {file_path: filePath},
      method: 'GET',
      success: function(data){
        //open file download in new tab
        if (data['success'] == true){
          window.open(data['download_url'], '_blank');
        } else {
          toastr.error('Unable To Download File.');
        };
      },
      error: function(data){
        toastr.error('Unable To Download File.');
      }
    });
  };

  followLink(e){
    window.open($(this).data('url'), '_blank');
  };

}
export let files_and_links = new FilesAndLinks();